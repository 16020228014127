import Ajax from './core/Ajax'
import { removeCookie } from '@/utils/cookie'
import getConfig from 'next/config'
import { localStorageService } from "@/utils/storageService"

const { publicRuntimeConfig } = getConfig()
const apiDomain = publicRuntimeConfig.apiDomain

//创建网络实体对象
const createAjaxInstance = (options?: any) => {
  return new Ajax({ timeout: 30000, withCredentials: true, ...options })
}

//业务网络对象
export const ajax = createAjaxInstance()

//刷新token使用(不走拦截器,避免循环问题)
export const fetch = createAjaxInstance()

//请求拦截器
ajax.axiosInstance.interceptors.request.use(
  async (config: any) => {
    // 设置请求header
    const customHeader = {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json',
      'Accept': '*/*',
      'Authorization': localStorageService.getItem('userToken'),
      'X-Service-Group': 'AG',
      'X-Certain-Group': 'AG'
    }
    config.headers = Object.assign({}, config.headers, customHeader)    
    return config
  },
  (error: any) => {
    return Promise.reject(error)
  }
)

//响应拦截器
ajax.axiosInstance.interceptors.response.use(
  (response: any) => {
    try {
      if (
        (response.status == 200 || response.status == 201)
      ) {
        if (response.data.code <= 100000) {
          return Promise.resolve(response.data)
        } else {
          return Promise.reject(response.data)
        }
      }

      return Promise.reject(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  (error: any) => {
    if (error.response.status == 401) {
      removeCookie('PHPSESSID')
      const currentPath = window.location.pathname;
      window.location.href = `${apiDomain}/login?redirect=${currentPath}`

      return Promise.reject(error.response.data)
    }
    return Promise.reject(error.response.data)
  }
)

export default {
  ajax,
  fetch
}
